import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import './TokenPurchase.css';

const TokenPurchase = ({ onBackClick, userId }) => {
	const [tokenOptions, setTokenOptions] = useState([]);
	const [selectedTokenOption, setSelectedTokenOption] = useState(null);
	const [promoCode, setPromoCode] = useState('');

	useEffect(() => {
		const fetchTokenOptions = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/token-options`
				);
				setTokenOptions(response.data);
			} catch (error) {
				console.error('Error:', error);
			}
		};

		fetchTokenOptions();
	}, []);

	const handleTokenOptionChange = (event) => {
		const optionId = event.target.value;
		const selectedOption = tokenOptions.find(
			(option) => option.id === optionId
		);
		setSelectedTokenOption(selectedOption);
	};

	const handlePromoCodeChange = (event) => {
		setPromoCode(event.target.value);
	};

	const handleTokenPurchase = () => {
		if (!selectedTokenOption) {
			console.log('Please select a token option');
			return;
		}
		// Process token purchase logic here
		console.log('Token purchase:', selectedTokenOption, promoCode);
	};

	const activePromoCode = async () => {
		if (promoCode === '') {
			console.log('Please enter a promo code');
			return;
		}

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/promo-codes/activate`,
				{
					code: promoCode,
					userId: userId,
				}
			);

			if (response.data) {
				toast.success('Promo code activated successfully');
			} else {
				alert('Promo code activation failed');
			}
		} catch (error) {
			console.error('Error activating promo code:', error);
		}
	};

	const handleBackClick = () => {
		onBackClick();
	};

	return (
		<div className='token-purchase-container'>
			<ToastContainer />
			<h1>Token Purchase</h1>
			<div className='token-option-section'>
				<label>Choose Token Quantity:</label>
				<select
					value={selectedTokenOption?.id}
					onChange={handleTokenOptionChange}>
					<option value=''>Select an option</option>
					{tokenOptions.map((option) => (
						<option key={option.id} value={option.id}>
							{option.quantity} Tokens - ${option.price}
						</option>
					))}
				</select>
				<button className='button-token' onClick={handleTokenPurchase}>
					Get Tokens
				</button>
			</div>
			<div className='promo-code-section'>
				<label>Enter Promo Code:</label>
				<input type='text' value={promoCode} onChange={handlePromoCodeChange} />
				<button className='button-token' onClick={activePromoCode}>
					Get Tokens
				</button>
			</div>
			<div className='button-section'>
				<div>
					<button>Button 1</button>
					<button>Button 2</button>
				</div>
				<div>
					<button>Button 3</button>
					<button>Button 4</button>
				</div>
			</div>
			<div>
				<h2>Helpful information</h2>
				<div>
					<button>Button 1</button>
					<button>Button 2</button>
					<button>Button 3</button>
					<button>Button 4</button>
				</div>
			</div>

			<button className='button-token' onClick={handleBackClick}>
				Back
			</button>
		</div>
	);
};

export default TokenPurchase;
