import React, { useState } from 'react';
import styles from './Prices.module.css';

const AddPriceForm = ({ onClose, addPrice, fetchData }) => {
	const [newPrice, setNewPrice] = useState({
		ai: '',
		setPrice: 0,
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setNewPrice({ ...newPrice, [name]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		addPrice(newPrice); // Вызываем функцию addPrice, передавая ей данные newPrice
		onClose();
		fetchData();
	};

	return (
		<div className={styles.modal}>
			<div className={styles.modalContent}>
				<span className={styles.close} onClick={onClose}>
					&times;
				</span>
				<h2>Add New Price</h2>
				<form onSubmit={handleSubmit} className={styles.addPriceForm}>
					<div>
						<label>AI</label>
						<input
							type='string'
							name='ai'
							value={newPrice.ai}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div>
						<label>Set Price:</label>
						<input
							type='number'
							name='setPrice'
							value={newPrice.setPrice}
							onChange={handleInputChange}
						/>
					</div>
					<button type='submit'>Add</button>
				</form>
			</div>
		</div>
	);
};

export default AddPriceForm;
