import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Auth.css';

const Auth = ({ onLogin }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isRegistering, setIsRegistering] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			onLogin(token);
		}
	}, [onLogin]);

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (isRegistering) {
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/auth/registration`, {
					email,
					password,
				});
				setIsRegistering(false);
			} catch (error) {
				setError(error.response.data.message);
			}
		} else {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/login`,
					{
						email,
						password,
					}
				);
				const token = response.data.token;
				localStorage.setItem('token', token); // Сохраняем токен в Local Storage
				onLogin(token);
			} catch (error) {
				setError(error.response.data.message);
				console.log('Login failed:', error);
			}
		}
	};

	return (
		<div className='auth-container'>
			<form className='auth-form' onSubmit={handleSubmit}>
				<h2>{isRegistering ? 'Register' : 'Login'}</h2>
				<input
					type='email'
					placeholder='Email'
					value={email}
					onChange={handleEmailChange}
				/>
				<input
					type='password'
					placeholder='Password'
					value={password}
					onChange={handlePasswordChange}
				/>
				<button type='submit'>{isRegistering ? 'Register' : 'Login'}</button>
				<p onClick={() => setIsRegistering(!isRegistering)}>
					{isRegistering
						? 'Already have an account? Login'
						: "Don't have an account? Register"}
				</p>
				{error && <div className='error-message'>{error}</div>}
			</form>
		</div>
	);
};

export default Auth;
