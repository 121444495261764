//TokenLinkTable.jsx
import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TokenLinkTable = ({ tokenLinks, deleteTokenLink, updateTokenLink }) => {
	const handleCopyTokenLink = (tokenLink) => {
		navigator.clipboard.writeText(tokenLink);
		toast('Ссылка скопирована', { type: 'success' });
	};

	const handleDeleteTokenLink = async (tokenLinkId) => {
		try {
			await deleteTokenLink(tokenLinkId);
		} catch (error) {
			console.error('API Delete TokenLink Error:', error);
		}
	};

	const handleUpdateTokenLink = async (tokenLinkId, data) => {
		try {
			await updateTokenLink(tokenLinkId, data);
		} catch (error) {
			console.error('API Update TokenLink Error:', error);
		}
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	};

	return (
		<div>
			<h2>Список ccылок</h2>
			<ToastContainer />
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Ссылка</th>
						<th>Дата создания</th>
						<th>Использования</th>
						<th>Скидка</th>
						<th>Активна</th>
						<th>Комментарий</th>
						<th>Действия</th>
					</tr>
				</thead>
				<tbody>
					{tokenLinks.map((tokenLink) => (
						<tr key={tokenLink.id}>
							<td>
								{`${process.env.REACT_APP_API_URL}/${tokenLink.token}`}
								<FontAwesomeIcon
									size='sm'
									icon={faCopy}
									onClick={() =>
										handleCopyTokenLink(
											`${process.env.REACT_APP_API_URL}/${tokenLink.token}`
										)
									}
									style={{ padding: '5px', cursor: 'pointer' }}
								/>
							</td>
							<td>{formatDate(tokenLink.createdAt)}</td>
							<td>{tokenLink.used}</td>
							<td>{tokenLink.discountTokens}</td>
							<td>
								<select
									defaultValue={tokenLink.isUsable ? 'Активна' : ''}
									onChange={(e) => {
										const isUsable = e.target.value === 'Активна';
										handleUpdateTokenLink(tokenLink.id, { isUsable });
									}}>
									<option value=''>Не Активна</option>
									<option value='Активна'>Активна</option>
								</select>
							</td>
							<td>
								{
									<input
										type='string'
										name='comment'
										defaultValue={tokenLink.comment || ''}
										onChange={(e) => {
											const comment = e.target.value;
											handleUpdateTokenLink(tokenLink.id, { comment });
										}}
									/>
								}
							</td>

							<td>
								<Button
									variant='danger'
									size='sm'
									onClick={() => handleDeleteTokenLink(tokenLink.id)}>
									Удалить
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default TokenLinkTable;
