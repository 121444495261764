import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Users.css';

const Users = ({ token }) => {
	const [users, setUsers] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [isDatePickerVisible, setDatePickerVisible] = useState(false);
	const searchContainerRef = useRef(null);

	const getUsers = useCallback(async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/users`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setUsers(response.data);
		} catch (error) {
			console.error(error);
		}
	}, [token]);

	useEffect(() => {
		getUsers();
	}, [getUsers]);

	const deleteUser = async (userId) => {
		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
		} catch (error) {
			console.error(error);
		}
	};

	const addRole = async (userId, role) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_API_URL}/users/role`,
				{ userId: userId, value: role },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			getUsers();
		} catch (error) {
			console.error(error);
		}
	};

	const banUser = async (userId) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_API_URL}/users/ban`,
				{ userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			getUsers();
		} catch (error) {
			console.error(error);
		}
	};

	const addToken = async (userId) => {
		try {
			await axios.post(
				`${process.env.REACT_APP_API_URL}/users/addToken`,
				{
					userId,
					chatToken: parseInt(
						users.find((user) => user.id === userId).tokenCount
					),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			getUsers();
		} catch (error) {
			console.error(error);
		}
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchText(value);
		setDatePickerVisible(value !== '');
	};

	const filteredUsers = users.filter((user) => {
		// const nameMatch = user.name
		// 	.toLowerCase()
		// 	.includes(searchText.toLowerCase());
		const emailMatch = user.email
			.toLowerCase()
			.includes(searchText.toLowerCase());
		return emailMatch;
		// return nameMatch || emailMatch;
	});

	const formatDate = (dateString) => {
		const date = new Date(dateString);

		const options = {
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			timeZoneName: 'short',
		};

		return date.toLocaleString('en-GB', options);
	};

	const handleDateChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const filterByDate = (user) => {
		if (!startDate || !endDate) {
			return true;
		}

		const createdAt = new Date(user.createdAt);
		return createdAt >= startDate && createdAt <= endDate;
	};

	const filteredUsersByDate = filteredUsers.filter(filterByDate);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (
				searchContainerRef.current &&
				!searchContainerRef.current.contains(e.target)
			) {
				setDatePickerVisible(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className='users-container'>
			<h2>Users</h2>
			<div className='search-container' ref={searchContainerRef}>
				<input
					type='text'
					value={searchText}
					onChange={handleSearch}
					onFocus={() => setDatePickerVisible(true)}
					placeholder='Search'
				/>
				{isDatePickerVisible && (
					<DatePicker
						selected={startDate}
						onChange={handleDateChange}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						isClearable
						showYearDropdown
						dateFormat='dd.MM.yyyy'
						placeholderText='Select date range'
					/>
				)}
			</div>

			<table className='users-table'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Date</th>
						<th>City</th>
						<th>Tokens</th>
						<th>Add Tokens</th>
						<th>Role</th>
						<th>Ban</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{filteredUsersByDate.map((user) => (
						<tr key={user.id}>
							<td>{user.id}</td>
							<td>{user.name}</td>
							<td>{user.email}</td>
							<td>{user.phone}</td>
							<td>{formatDate(user.createdAt)}</td>
							<td>{user.city}</td>
							<td>{user.chatToken}</td>
							<td>
								<input
									className='token-input'
									type='text'
									value={user.tokenCount || ''}
									onChange={(e) => {
										const newTokenCount = e.target.value;
										setUsers((prevUsers) =>
											prevUsers.map((u) =>
												u.id === user.id
													? { ...u, tokenCount: newTokenCount }
													: u
											)
										);
									}}
								/>
								<button onClick={() => addToken(user.id)}>Add Tokens</button>
							</td>
							<td>
								<select
									value={user.roles.length > 0 ? user.roles[0].value : ''}
									onChange={(e) => {
										const role = e.target.value;
										addRole(user.id, role);
									}}>
									<option value='USER'>USER</option>
									<option value='ADMIN'>SUPER-ADMIN</option>
								</select>
							</td>
							<td>
								<select
									value={user.banned ? 'Забанен' : ''}
									onChange={(e) => {
										banUser(user.id);
									}}>
									<option value=''>Не забанен</option>
									<option value='Забанен'>Забанен</option>
								</select>
							</td>
							<td>
								<button onClick={() => deleteUser(user.id)}>Delete</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Users;
