// CompanyChat.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import Main from '../Main/Main';
import './CompanyChat.css';
import Header from '../Header/Header';
import TokenPurchase from '../TokenPurchase/TokenPurchase';

const CompanyChat = ({
	companies,
	selectedCompany,
	onCompanyClick,
	onAddCompany,
	onDeleteCompany,
	onLogout,
	userId,
	userEmail,
}) => {
	const [cards, setCards] = useState([]);
	const [cardNames, setCardNames] = useState([]);
	const [filteredCards, setFilteredCards] = useState([]);
	const [authors, setAuthors] = useState([]);
	const [showTokenPurchase, setShowTokenPurchase] = useState(false);
	const [isSearchClicked, setIsSearchClicked] = useState(false);

	useEffect(() => {
		if (selectedCompany) {
			fetchCards();
		}
		// Получение карточек с сервера при загрузке страницы или выборе компании
		async function fetchCards() {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/companies/cards/${selectedCompany.id}`
				);
				console.log('get card', response.data);
				const cardsFromServer = response.data;
				setCards(cardsFromServer);
				setFilteredCards(cardsFromServer);
				const cardTitles = cardsFromServer.map((card) => card.title);
				setCardNames(cardTitles);

				const authors = cardsFromServer.map((card) => card.author);
				const uniqueAuthors = [...new Set(authors)];
				setAuthors(uniqueAuthors);
			} catch (error) {
				console.error(error);
			}
		}
	}, [selectedCompany]);

	const [newCompanyData, setNewCompanyData] = useState({
		name: '',
		description: '',
		members: [],
	});

	// Обработчик добавления компании
	const handleAddCompany = () => {
		// Создаем новую компанию на основе введенных данных
		const newCompany = {
			id: Date.now(),
			name:
				newCompanyData.name.trim() !== ''
					? newCompanyData.name
					: `Company ${companies.length + 1}`,
			description: newCompanyData.description,
			members: newCompanyData.members,
		};

		// Вызываем функцию для добавления компании
		onAddCompany(newCompany);

		// Сбрасываем данные новой компании
		setNewCompanyData({
			name: '',
			description: '',
			members: [],
		});
	};
	const createCard = (cardData) => {
		const currentDate = new Date();
		const newCard = {
			id: cardData.id,
			companyId: selectedCompany.id, // Присвоение идентификатора выбранной компании
			title: cardData.title,
			description: cardData.description,
			targetAudience: cardData.targetAudience,
			pains: cardData.pains,
			keywords: cardData.keywords,
			socialMedia: cardData.socialMedia,
			author: userEmail,
			createdAt: currentDate,
		};

		setCards((prevCards) => [...prevCards, newCard]);

		setCardNames((prevCardNames) => [...prevCardNames, cardData.title]);
	};

	const handleSearch = (searchValue) => {
		const filtered = cards.filter((card) =>
			card.title.toLowerCase().includes(searchValue.toLowerCase())
		);
		setFilteredCards(filtered);
	};

	const handleTokenPurchaseClick = () => {
		setShowTokenPurchase(true);
	};

	const handleBackClick = () => {
		setShowTokenPurchase(false);
	};

	return (
		<div className='company-chat'>
			{showTokenPurchase ? (
				<TokenPurchase onBackClick={handleBackClick} userId={userId} />
			) : (
				<>
					{/* Компонент Sidebar */}
					<Sidebar
						companies={companies}
						selectedCompany={selectedCompany}
						onCompanyClick={onCompanyClick}
						onDeleteCompany={onDeleteCompany}
						onAddCompany={handleAddCompany}
						newCompanyData={newCompanyData}
						setNewCompanyData={setNewCompanyData}
						cardNames={cardNames}
						cards={cards}
						userId={userId}
					/>
					<div className='company-chat-content'>
						<Header
							handleSearch={handleSearch}
							authors={authors}
							keywords={[]}
							cards={cards}
							setFilteredCards={setFilteredCards}
							isSearchClicked={isSearchClicked}
							setIsSearchClicked={setIsSearchClicked}
							onLogout={onLogout}
							userId={userId}
							onTokenPurchaseClick={handleTokenPurchaseClick}
						/>
						{/* Компонент Main */}
						<Main
							selectedCompany={selectedCompany}
							cards={cards} // Передаем состояние cards в компонент Main
							filteredCards={filteredCards}
							createCard={createCard} // Передаем функцию createCard в компонент Main
							userId={userId}
							userEmail={userEmail}
							isSearchClicked={isSearchClicked}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default CompanyChat;
