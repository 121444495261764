// PromoCode.jsx
import React, { useState } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PromoCodeTable from './PromoCodeTable';
import usePromoCodes from './usePromoCodes';
import TokenLink from '../TokenLink/TokenLink';

const PromoCode = () => {
	const [showModal, setShowModal] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const { promoCodes, createPromoCode, deletePromoCode } = usePromoCodes();

	const onSubmit = async (data) => {
		try {
			await createPromoCode(data);
			reset();
			setShowModal(false);
		} catch (error) {
			console.error('API Error:', error);
		}
	};

	return (
		<>
			<Container
				fluid
				className='d-flex flex-column align-items-center justify-content-center'
				style={{ padding: '20px' }}>
				<div className='d-flex align-items-center justify-content-center mb-4'>
					<Button variant='primary' onClick={() => setShowModal(true)}>
						Создать промокоды
					</Button>
				</div>
				<Modal show={showModal} onHide={() => setShowModal(false)} centered>
					<Modal.Header closeButton>
						<Modal.Title>Создание промокодов</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group>
								<Form.Label>Скидка</Form.Label>
								<Form.Control
									type='number'
									name='discount'
									{...register('discount', { required: true })}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Количество промокодов</Form.Label>
								<Form.Control
									type='number'
									name='quantity'
									{...register('quantity', { required: true })}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Срок действия</Form.Label>
								<Form.Control
									type='date'
									name='expiresAt'
									{...register('expiresAt', { required: true })}
								/>
							</Form.Group>
							<Button variant='primary' type='submit'>
								Создать
							</Button>
						</Form>
					</Modal.Body>
				</Modal>
				<PromoCodeTable
					promoCodes={promoCodes}
					deletePromoCode={deletePromoCode}
				/>
			</Container>
			<TokenLink />
		</>
	);
};

export default PromoCode;
