//Sidebar.jsx
import React, { useState } from 'react';
import CompanySettingsModal from './CompanySettingsModal';
import { Button } from 'react-bootstrap';
import './Sidebar.css';
import { Input } from 'reactstrap';

const Sidebar = ({
	companies,
	selectedCompany,
	onCompanyClick,
	onDeleteCompany,
	onAddCompany,
	newCompanyData,
	setNewCompanyData,
	cardNames,
	cards,
	userId,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);
	const [settingsModalOpen, setSettingsModalOpen] = useState(false);
	const [selectedCompanyForSettings, setSelectedCompanyForSettings] =
		useState(null);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [companyToDelete, setCompanyToDelete] = useState(null);

	const handleToggleSettingsModal = (company) => {
		setSelectedCompanyForSettings(company);
		setSettingsModalOpen(!settingsModalOpen);
	};

	const handleModalOpen = () => {
		setIsModalOpen(true);
		setIsCompanyListOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCompanySubmit = () => {
		const newCompany = {
			id: Date.now(),
			name:
				newCompanyData.name.trim() !== ''
					? newCompanyData.name
					: `Company ${companies.length + 1}`,
			description: newCompanyData.description,
			members: newCompanyData.members,
		};

		onAddCompany(newCompany);

		setNewCompanyData({
			name: '',
			description: '',
			members: [],
		});

		handleModalClose();
	};

	const handleNewCompanyDataChange = (e) => {
		const { name, value } = e.target;
		setNewCompanyData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleMemberChange = (e, index) => {
		const updatedMembers = [...newCompanyData.members];
		updatedMembers[index] = e.target.value;
		setNewCompanyData((prevState) => ({
			...prevState,
			members: updatedMembers,
		}));
	};

	const handleAddMember = () => {
		setNewCompanyData((prevState) => ({
			...prevState,
			members: [...prevState.members, ''],
		}));
	};

	const handleRemoveMember = (index) => {
		const updatedMembers = [...newCompanyData.members];
		updatedMembers.splice(index, 1);
		setNewCompanyData((prevState) => ({
			...prevState,
			members: updatedMembers,
		}));
	};

	const handleToggleCardNames = (companyId) => {
		const updatedSelectedCompany =
			selectedCompany && selectedCompany.id === companyId
				? null
				: companies.find((company) => company.id === companyId);
		onCompanyClick(updatedSelectedCompany);
		handleToggleCompanyList();
	};

	const handleToggleCompanyList = () => {
		if (companies.length > 0) {
			setIsCompanyListOpen((prevState) => !prevState);
		} else {
			onCompanyClick(null);
		}
	};

	return (
		<div className='sidebar'>
			<Button
				variant='outline-primary'
				className='mb-2'
				style={{
					color: 'rgba(121, 136, 244, 1)',
					fontWeight: 600,
					border: 'none',
					background: 'white',
				}}
				onClick={handleModalOpen}>
				Add Company
			</Button>
			<div className='list-group'>
				<div
					className='list-group-item company-heading'
					onClick={handleToggleCompanyList}>
					{selectedCompany ? selectedCompany.name : 'All Companies'}
					{companies.length > 0 && (
						<span
							className={`arrow-icon ${isCompanyListOpen ? 'down' : 'right'}`}>
							{isCompanyListOpen ? '▼' : '▶'}
						</span>
					)}
				</div>
				{isCompanyListOpen && (
					<ul className='list-group'>
						{companies.map((company) => (
							<li key={company.id}>
								<div
									className={`list-group-item company-item ${
										selectedCompany && selectedCompany.id === company.id
											? 'selected'
											: ''
									}`}
									onClick={() => handleToggleCardNames(company.id)}>
									<div className='company-details'>
										<h5>{company.name}</h5>
										{company.userId === userId && (
											<div className='company-actions'>
												<button
													className='settings-company-button btn btn-link'
													onClick={() => handleToggleSettingsModal(company)}>
													⚙️
												</button>
												<button
													className='delete-company-button btn btn-link'
													onClick={() => {
														setCompanyToDelete(company);
														setDeleteConfirmationOpen(true);
													}}>
													X
												</button>
											</div>
										)}
									</div>
								</div>
							</li>
						))}
					</ul>
				)}
			</div>

			{deleteConfirmationOpen && (
				<div className='modal fade show' style={{ display: 'block' }}>
					<div className='modal-dialog modal-dialog-centered'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>Delete Company</h5>
							</div>
							<div className='modal-body'>
								<p>
									Are you sure you want to delete the company:{' '}
									{companyToDelete.name}?
								</p>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-secondary'
									onClick={() => setDeleteConfirmationOpen(false)}>
									Cancel
								</button>
								<button
									type='button'
									className='btn btn-danger'
									onClick={() => {
										onDeleteCompany(companyToDelete);
										setDeleteConfirmationOpen(false);
									}}>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* {!isCompanyListOpen && selectedCompany && !isCompanyEmpty() && (
				<div className='card-names'>
					{cardNames
						.filter((name) => {
							const card = cards.find((card) => card.title === name);
							return card && card.companyId === selectedCompany.id;
						})
						.map((name, index) => (
							<p key={index}>{name}</p>
						))}
				</div>
			)} */}

			{isModalOpen && (
				<div className='modal'>
					<div className='modal-content'>
						<h3>Create New Company</h3>
						<form onSubmit={handleCompanySubmit}>
							<label htmlFor='companyName'>Company Name:</label>
							<input
								type='text'
								id='companyName'
								name='name'
								value={newCompanyData.name}
								onChange={handleNewCompanyDataChange}
								required
								className='form-control'
							/>

							<label htmlFor='companyDescription'>Company Description:</label>
							<textarea
								id='companyDescription'
								name='description'
								value={newCompanyData.description}
								onChange={handleNewCompanyDataChange}
								required
								className='form-control'></textarea>

							<label>Company Members:</label>
							{newCompanyData.members.map((member, index) => (
								<div key={index} className='input-group mb-2'>
									<Input
										placeholder='Enter user email'
										type='email'
										value={member}
										onChange={(e) => handleMemberChange(e, index)}
										required
										className='form-control'
									/>
									<div className='input-group-append'>
										<Button
											style={{ marginLeft: '10px' }}
											type='button'
											onClick={() => handleRemoveMember(index)}
											className='btn btn-secondary'>
											Close
										</Button>
									</div>
								</div>
							))}
							<button
								type='button'
								onClick={handleAddMember}
								className='btn btn-primary'>
								Add Member
							</button>

							<div className='modal-buttons'>
								<button
									type='button'
									onClick={handleModalClose}
									className='btn btn-secondary'>
									Cancel
								</button>
								<button type='submit' className='btn btn-success'>
									Create
								</button>
							</div>
						</form>
					</div>
				</div>
			)}

			{settingsModalOpen && selectedCompanyForSettings && (
				<CompanySettingsModal
					company={selectedCompanyForSettings}
					isOpen={settingsModalOpen}
					onClose={() => setSettingsModalOpen(false)}
				/>
			)}
		</div>
	);
};

export default Sidebar;
