import { useState } from 'react';
import Users from '../User/Users';
import Prices from '../Prices/Prices';
import PromoCode from '../PromoCode/PromoCode';

const Admin = ({ token, onLogout }) => {
	const [activeTab, setActiveTab] = useState('users');

	const renderContent = () => {
		switch (activeTab) {
			case 'users':
				return <Users token={token} />;
			case 'prices':
				return <Prices />;
			case 'promoCode':
				return <PromoCode />;
			default:
				return null;
		}
	};

	return (
		<div>
			<div className='navigation'>
				<button
					className='navigation-btn'
					onClick={() => setActiveTab('users')}>
					Users
				</button>
				<button
					className='navigation-btn'
					onClick={() => setActiveTab('prices')}>
					Prices
				</button>
				<button
					className='navigation-btn'
					onClick={() => setActiveTab('promoCode')}>
					Promo-code
				</button>
				<button className='logout-button' onClick={onLogout}>
					Logout
				</button>
			</div>
			<div className='content'>{renderContent()}</div>
		</div>
	);
};

export default Admin;
