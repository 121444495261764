//Card.jsx
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faInstagram,
	faVk,
} from '@fortawesome/free-brands-svg-icons';
import './Card.css';

const Card = ({ card, companyId, onClick }) => {
	const [showChatWindow, setShowChatWindow] = useState(false);

	const toggleChatWindow = () => {
		setShowChatWindow(!showChatWindow);
	};

	const handleCardClick = () => {
		onClick(card.id); // Вызываем обработчик клика по карточке и передаем выбранную карточку
	};

	const formattedDate = new Date(card.createdAt)
		.toLocaleDateString('en-GB')
		.split('/')
		.join('.');

	return (
		<div className='card' onClick={handleCardClick}>
			{card.companyId === companyId && (
				<>
					<h3>{card.title}</h3>
					<button onClick={toggleChatWindow}>
						{showChatWindow ? 'Hide Chat' : 'Show Chat'}
					</button>
					<div className='card-icon'>
						{
							<FontAwesomeIcon
								icon={faFacebook}
								style={{
									marginRight: '11px',
									fontSize: '27px',
									color: '#adadad',
								}}
							/>
						}
						{
							<FontAwesomeIcon
								icon={faInstagram}
								style={{
									marginRight: '11px',
									fontSize: '27px',
									color: '#adadad',
								}}
							/>
						}
						{
							<FontAwesomeIcon
								icon={faVk}
								style={{ fontSize: '27px', color: '#adadad' }}
							/>
						}
					</div>
					<p>{card.author}</p>
					<p>{formattedDate}</p>
				</>
			)}
		</div>
	);
};

export default Card;
