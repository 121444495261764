// useTokenLinks.js
import { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInterceptor';

const useTokenLinks = () => {
	const [tokenLinks, setTokenLinks] = useState([]);

	useEffect(() => {
		fetchTokenLinks();
	}, []);

	const fetchTokenLinks = async () => {
		try {
			const response = await axiosInstance.get(
				`${process.env.REACT_APP_API_URL}/token-link`
			);
			setTokenLinks(response.data);
		} catch (error) {
			console.error('API Table TokenLink Error:', error);
		}
	};

	const createTokenLink = async (data) => {
		console.log(data);
		try {
			const response = await axiosInstance.post(
				`${process.env.REACT_APP_API_URL}/token-link`,
				data
			);
			console.log(response);
			setTokenLinks((tokenLinks) => [...tokenLinks, ...response.data]);
		} catch (error) {
			console.error('API Error:', error);
		}
	};

	const updateTokenLink = async (tokenLinkId, data) => {
		try {
			const response = await axiosInstance.put(
				`${process.env.REACT_APP_API_URL}/token-link/${tokenLinkId}`,
				data
			);

			setTokenLinks((prevTokenLinks) =>
				prevTokenLinks.map((tokenLink) =>
					tokenLink.id === tokenLinkId
						? { ...tokenLink, ...response.data }
						: tokenLink
				)
			);
		} catch (error) {
			console.error('API Update TokenLink Error:', error);
		}
	};

	const deleteTokenLink = async (tokenLinkId) => {
		try {
			await axiosInstance.delete(
				`${process.env.REACT_APP_API_URL}/token-link/${tokenLinkId}`
			);

			setTokenLinks((prevTokenLinks) =>
				prevTokenLinks.filter((tokenLink) => tokenLink.id !== tokenLinkId)
			);
		} catch (error) {
			console.error('API Delete TokenLink Error:', error);
		}
	};

	return {
		tokenLinks,
		createTokenLink,
		updateTokenLink,
		deleteTokenLink,
	};
};

export default useTokenLinks;
