import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Main.css';
import ModalCreateCard from '../ModalCreateCard/ModalCreateCard';
import Card from '../Card/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Input, Label } from 'reactstrap';

const Main = ({
	selectedCompany,
	filteredCards,
	createCard,
	cards,
	userId,
	userEmail,
	isSearchClicked,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedCardId, setSelectedCardId] = useState(null);
	const [chats, setChats] = useState(
		JSON.parse(localStorage.getItem('chats')) || []
	);
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [useChatGpt, setUseChatGpt] = useState(true);
	const messageListRef = useRef(null);
	const textareaRef = useRef(null);

	useEffect(() => {
		adjustTextareaHeight();
	}, [message]);

	const adjustTextareaHeight = () => {
		if (textareaRef.current) {
			textareaRef.current.style.height = 'auto';
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	};

	const handleCardClick = (cardId) => {
		setSelectedCardId(cardId);

		const existingChat = chats.find((chat) => chat.cardId === cardId);
		if (existingChat) {
			setChats((prevChats) =>
				prevChats.map((chat) =>
					chat.cardId === cardId ? { ...chat, selected: true } : chat
				)
			);
		} else {
			setChats((prevChats) => [
				...prevChats,
				{ cardId, selected: true, messages: [], responseMessages: [] },
			]);
		}
	};

	useEffect(() => {
		if (selectedCardId) {
			const loadChatMessages = async () => {
				try {
					const url = `${process.env.REACT_APP_API_URL}/chat/messages?cardId=${selectedCardId}`;
					const response = await axios.get(url);
					const savedMessages = response.data;

					setChats((prevChats) =>
						prevChats.map((chat) =>
							chat.cardId === selectedCardId
								? {
										...chat,
										messages: savedMessages.map((msg) => ({
											id: msg.id,
											content: msg.content,
											isQuestion: msg.isQuestion,
										})),
										responseMessages: [],
								  }
								: chat
						)
					);
				} catch (error) {
					console.error('Error:', error);
				}
			};

			loadChatMessages();
		}
	}, [selectedCardId]);

	useEffect(() => {
		setSelectedCardId(null);
	}, [selectedCompany]);

	useEffect(() => {
		if (messageListRef.current) {
			messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
		}
	}, [chats]);

	useEffect(() => {
		localStorage.setItem('chats', JSON.stringify(chats));
	}, [chats]);

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const sendMessage = async () => {
		const chat = chats.find((chat) => chat.cardId === selectedCardId);
		if (!chat) return;

		try {
			const url = `${process.env.REACT_APP_API_URL}/chat/message`;
			setIsLoading(true);

			const payload = {
				userId,
				message,
				cardId: selectedCardId,
				isQuestion: true, // Добавляем флаг вопроса в запрос
				useChatGpt: useChatGpt,
			};

			const response = await axios.post(url, payload);
			const newQuestion = {
				id: Date.now(),
				content: message,
				isQuestion: true,
			};
			const newAnswer = {
				id: Date.now() + 1,
				content: response.data,
				isQuestion: false,
			};

			setChats((prevChats) =>
				prevChats.map((chat) =>
					chat.cardId === selectedCardId
						? {
								...chat,
								messages: [...chat.messages, newQuestion],
								responseMessages: [...chat.responseMessages, newAnswer],
						  }
						: chat
				)
			);

			setMessage('');
			setIsLoading(false);
		} catch (error) {
			console.error('Error:', error);
			setIsLoading(false);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			sendMessage();
		}
	};

	const chatMessages = chats.reduce((acc, chat) => {
		if (chat.cardId === selectedCardId && chat.selected) {
			for (let i = 0; i < chat.messages.length; i++) {
				acc.push(chat.messages[i]);
				if (chat.responseMessages[i]) {
					acc.push(chat.responseMessages[i]);
				}
			}
		}
		return acc;
	}, []);

	useEffect(() => {
		if (selectedCompany) {
			setSelectedCardId(null); // Сброс выбранной карточки при смене компании
		}
	}, [selectedCompany]);

	const handleGoBack = () => {
		setSelectedCardId(null);
	};

	return (
		<div className='main'>
			{selectedCompany && selectedCardId ? (
				<>
					<Button variant='dark' size='sm' onClick={handleGoBack}>
						Back
					</Button>
					<div className='chat-window'>
						<div className='message-list-container' ref={messageListRef}>
							{chatMessages.map((message) => (
								<div
									key={message.id}
									className={`message ${
										message.isQuestion ? 'question' : 'answer'
									}`}>
									{message.content}
								</div>
							))}
						</div>
						<div className='message-input'>
							<textarea
								ref={textareaRef}
								placeholder='Type your message...'
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								onKeyDown={handleKeyDown}></textarea>
							<button onClick={sendMessage}>Send</button>
						</div>
						<div className='use-chatgpt-checkbox'>
							<Label>
								<Input
									type='checkbox'
									checked={useChatGpt}
									onChange={(e) => setUseChatGpt(e.target.checked)}
								/>
								Use ChatGPT
							</Label>
						</div>
						{isLoading && <div className='loader'></div>}
					</div>
				</>
			) : (
				<>
					{selectedCompany ? (
						<>
							{isSearchClicked && filteredCards.length === 0 ? (
								<div className='cards-not-found'>Cards not found</div>
							) : (
								<>
									<div className='main-cards-container'>
										{(isSearchClicked ? filteredCards : cards).map((card) => (
											<Card
												key={card.id}
												card={card}
												companyId={selectedCompany.id}
												onClick={handleCardClick}
											/>
										))}
										{showModal && (
											<ModalCreateCard
												selectedCompany={selectedCompany}
												createCard={createCard}
												userEmail={userEmail}
												closeModal={toggleModal}
											/>
										)}
									</div>
									<Button className='custom-button' onClick={toggleModal}>
										{' '}
										<FontAwesomeIcon icon={faPlus} />
									</Button>
								</>
							)}
						</>
					) : (
						<div className='no-company-selected'>No company selected</div>
					)}
				</>
			)}
		</div>
	);
};

export default Main;
