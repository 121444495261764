import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

function ModalCreateCard({
	closeModal,
	createCard,
	selectedCompany,
	userEmail,
}) {
	const [cardData, setCardData] = useState({
		title: '',
		description: '',
		targetAudience: '',
		pains: '',
		keywords: '',
		socialMedia: '',
		author: userEmail,
		dateTime: '',
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setCardData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const currentDate = new Date().toISOString().slice(0, 16); // Получение текущей даты и времени
		setCardData((prevData) => ({ ...prevData, dateTime: currentDate }));
		// Проверяем, что все поля заполнены перед созданием карточки
		if (cardData.title.trim() === '') {
			// Выводим сообщение об ошибке или предупреждение
			return;
		}

		// Отправляем запрос на сервер для создания карточки
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/companies/cards/${selectedCompany.id}`,
				cardData
			);

			// Получаем созданную карточку из ответа сервера (если требуется)
			const createdCard = response.data;

			// Вызываем функцию createCard для добавления карточки в состояние компонента
			createCard(createdCard);
			closeModal();
		} catch (error) {
			// Обрабатываем ошибку, если запрос не удалось выполнить
			console.error(error);
		}
	};

	return (
		<Modal show onHide={closeModal} style={{ borderRadius: '15px' }}>
			<Modal.Header closeButton>
				<Modal.Title>Create New Card</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group>
						<Form.Label>Title:</Form.Label>
						<Form.Control
							type='text'
							name='title'
							value={cardData.title}
							onChange={handleInputChange}
							required
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Description:</Form.Label>
						<Form.Control
							as='textarea'
							name='description'
							value={cardData.description}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Target Audience:</Form.Label>
						<Form.Control
							type='text'
							name='targetAudience'
							value={cardData.targetAudience}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Pains:</Form.Label>
						<Form.Control
							type='text'
							name='pains'
							value={cardData.pains}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Keywords:</Form.Label>
						<Form.Control
							type='text'
							name='keywords'
							value={cardData.keywords}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Social Media:</Form.Label>
						<Form.Control
							type='text'
							name='socialMedia'
							value={cardData.socialMedia}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Modal.Footer>
						<Button variant='secondary' onClick={closeModal}>
							Cancel
						</Button>
						<Button type='submit' variant='primary'>
							Create
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default ModalCreateCard;
