import React, { useState, useEffect } from 'react';
import { decodeToken } from 'react-jwt';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const User = ({ onLogout, userId, onTokenPurchaseClick }) => {
	const [userEmail, setUserEmail] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [tokenCount, setTokenCount] = useState(0);

	useEffect(() => {
		const fetchUserEmail = async () => {
			const token = localStorage.getItem('token');
			if (token) {
				const decodedToken = decodeToken(token);
				setUserEmail(decodedToken.email);
			}
		};

		fetchUserEmail();
	}, []);

	const toggleUserMenu = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (isOpen) {
			const fetchTokenCount = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/users/${userId}/tokenCount`
					);
					const tokenCount = response.data;
					setTokenCount(tokenCount);
				} catch (error) {
					console.error('Ошибка при получении остатка токенов', error);
				}
			};

			fetchTokenCount();
		}
	}, [isOpen, userId]);

	return (
		<div>
			<Button variant='dark' onClick={toggleUserMenu}>
				Привет {userEmail}
			</Button>
			{isOpen && (
				<div className='user-menu'>
					<div>Остаток токенов: {tokenCount}</div>
					<button className='logout-button' onClick={onTokenPurchaseClick}>
						Купить токены
					</button>
					<button className='logout-button' onClick={onLogout}>
						Logout
					</button>
				</div>
			)}
		</div>
	);
};

export default User;
