// PromoCodeTable.jsx
import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PromoCodeTable = ({ promoCodes, deletePromoCode }) => {
	const handleCopyPromoCode = (promoCode) => {
		navigator.clipboard.writeText(promoCode);
		toast('Промокод скопирован', { type: 'success' });
	};

	const handleDeletePromoCode = async (promoCodeId) => {
		try {
			await deletePromoCode(promoCodeId);
		} catch (error) {
			console.error('API Delete PromoCode Error:', error);
		}
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	};

	return (
		<div>
			<h2>Список промокодов</h2>
			<ToastContainer />
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Промокод</th>
						<th>Скидка</th>
						<th>Срок действия</th>
						<th>Статус использования</th>
						<th>Действия</th>
					</tr>
				</thead>
				<tbody>
					{promoCodes.map((promoCode) => (
						<tr key={promoCode.id}>
							<td>
								{promoCode.code}
								<FontAwesomeIcon
									size='sm'
									icon={faCopy}
									onClick={() => handleCopyPromoCode(promoCode.code)}
									style={{ padding: '5px', cursor: 'pointer' }}
								/>
							</td>
							<td>{promoCode.discount}</td>
							<td>{formatDate(promoCode.expiresAt)}</td>
							<td>{promoCode.used ? 'Использован' : 'Не использован'}</td>
							<td>
								<Button
									variant='danger'
									size='sm'
									onClick={() => handleDeletePromoCode(promoCode.id)}>
									Удалить
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default PromoCodeTable;
