import React, { useState } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import TokenLinkTable from './TokenLinkTable';
import useTokenLinks from './useTokenLinks';

const TokenLink = () => {
	const [showModal, setShowModal] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const { tokenLinks, createTokenLink, updateTokenLink, deleteTokenLink } =
		useTokenLinks();

	const onSubmit = async (data) => {
		const formData = {
			discountTokens: parseInt(data.discountTokens),
			numberOfLinks: parseInt(data.numberOfLinks),
		};
		try {
			await createTokenLink(formData);
			reset();
			setShowModal(false);
		} catch (error) {
			console.error('API Error:', error);
		}
	};

	return (
		<Container
			fluid
			className='d-flex flex-column align-items-center justify-content-center'
			style={{ padding: '20px' }}>
			<div className='d-flex align-items-center justify-content-center mb-4'>
				<Button variant='primary' onClick={() => setShowModal(true)}>
					Создать ссылки
				</Button>
			</div>
			<Modal show={showModal} onHide={() => setShowModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>Создание ссылок</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Скидка</Form.Label>
							<Form.Control
								type='number'
								name='discountTokens'
								{...register('discountTokens', { required: true })}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Количество ссылок</Form.Label>
							<Form.Control
								type='number'
								name='numberOfLinks'
								{...register('numberOfLinks', { required: true })}
							/>
						</Form.Group>
						<Button variant='primary' type='submit'>
							Создать
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
			<TokenLinkTable
				tokenLinks={tokenLinks}
				updateTokenLink={updateTokenLink}
				deleteTokenLink={deleteTokenLink}
			/>
		</Container>
	);
};

export default TokenLink;
