// usePromoCodes.js
import { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInterceptor';

const usePromoCodes = () => {
	const [promoCodes, setPromoCodes] = useState([]);

	useEffect(() => {
		fetchPromoCodes();
	}, []);

	const fetchPromoCodes = async () => {
		try {
			const response = await axiosInstance.get(
				`${process.env.REACT_APP_API_URL}/promo-codes`
			);
			setPromoCodes(response.data);
		} catch (error) {
			console.error('API Table PromoCode Error:', error);
		}
	};

	const createPromoCode = async (data) => {
		try {
			const response = await axiosInstance.post(
				`${process.env.REACT_APP_API_URL}/promo-codes`,
				data
			);
			setPromoCodes((promoCodes) => [...promoCodes, ...response.data]);
		} catch (error) {
			console.error('API Error:', error);
		}
	};

	const deletePromoCode = async (promoCodeId) => {
		try {
			await axiosInstance.delete(
				`${process.env.REACT_APP_API_URL}/promo-codes/${promoCodeId}`
			);

			setPromoCodes((prevPromoCodes) =>
				prevPromoCodes.filter((promoCode) => promoCode.id !== promoCodeId)
			);
		} catch (error) {
			console.error('API Delete PromoCode Error:', error);
		}
	};

	return {
		promoCodes,
		createPromoCode,
		deletePromoCode,
	};
};

export default usePromoCodes;
