import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Header.css';
import { Input } from 'reactstrap';
import { Button } from 'react-bootstrap';
import User from '../User/User';

const Header = ({
	handleSearch,
	authors,
	keywords,
	setFilteredCards,
	cards,
	isSearchClicked,
	setIsSearchClicked,
	onLogout,
	userId,
	onTokenPurchaseClick,
}) => {
	const [searchValue, setSearchValue] = useState('');
	const [selectedAuthor, setSelectedAuthor] = useState('');
	const [selectedKeyword, setSelectedKeyword] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	// const [isSearchClicked, setIsSearchClicked] = useState(false);

	const handleSearchByAuthor = (author, keyword, startDate, endDate) => {
		let filtered = cards;

		if (author !== '') {
			filtered = filtered.filter(
				(card) =>
					card.author &&
					card.author.toLowerCase().includes(author.toLowerCase())
			);
		}

		if (keyword !== '') {
			filtered = filtered.filter(
				(card) => card.keywords && card.keywords.includes(keyword.toLowerCase())
			);
		}

		if (startDate && endDate) {
			filtered = filtered.filter((card) => {
				const cardDate = new Date(card.createdAt);
				console.log(cardDate);
				return cardDate >= startDate && cardDate <= endDate;
			});
		}

		setFilteredCards(filtered);
	};

	const handleChange = (event) => {
		const value = event.target.value;
		setSearchValue(value);
		setIsSearchClicked(value !== '');
		handleSearch(value);
	};

	const handleAuthorChange = (event) => {
		const author = event.target.value;
		setSelectedAuthor(author);
		handleSearchByAuthor(author, selectedKeyword, startDate, endDate);
	};

	const handleKeywordChange = (event) => {
		const keyword = event.target.value;
		setSelectedKeyword(keyword);
		handleSearchByAuthor(selectedAuthor, keyword, startDate, endDate);
	};

	const handleDateChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		console.log('Новая дата начала:', start);
		console.log('Новая дата окончания:', end);
		handleSearchByAuthor(selectedAuthor, selectedKeyword, start, end);
	};

	return (
		<div className='header'>
			<div className='header-container'>
				<Input
					type='text'
					value={searchValue}
					onChange={handleChange}
					onFocus={() => setIsSearchClicked(true)}
					placeholder='Поиск карточек'
				/>
				<div className='user-section'>
					<User
						onLogout={onLogout}
						userId={userId}
						onTokenPurchaseClick={onTokenPurchaseClick}
					/>
				</div>
			</div>
			{isSearchClicked && (
				<div className='header-options'>
					<select value={selectedAuthor} onChange={handleAuthorChange}>
						<option value=''>Все авторы</option>
						{authors.map((author) => (
							<option key={author} value={author}>
								{author}
							</option>
						))}
					</select>
					<select value={selectedKeyword} onChange={handleKeywordChange}>
						<option value=''>Все ключевые слова</option>
						{/* {keywords.map((keyword) => (
							<option key={keyword} value={keyword}>
								{keyword}
							</option>
						))} */}
					</select>
					<div className='date-picker-container'>
						<DatePicker
							selected={startDate}
							onChange={handleDateChange}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							placeholderText='Выберите диапазон дат'
							isClearable
							showYearDropdown
							dateFormat='dd.MM.yyyy'
						/>
					</div>
					<Button
						variant='dark'
						size='sm'
						style={{ marginLeft: '30px' }}
						onClick={() => setIsSearchClicked(false)}>
						Close
					</Button>
				</div>
			)}
		</div>
	);
};

export default Header;
