import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInterceptor';
import AddPriceForm from './AddPriceForm';
import SalePricesModal from './SalePricesModal';
import styles from './Prices.module.css';

const Prices = () => {
	const [prices, setPrices] = useState([]);
	const [salePrices, setSalePrices] = useState([]);
	const [showAddForm, setShowAddForm] = useState(false);
	const [showSalePricesModal, setShowSalePricesModal] = useState(false);
	const sortedPrices = prices.slice().sort((a, b) => a.id - b.id);
	const sortedSalePrices = salePrices.slice().sort((a, b) => a.id - b.id);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await axiosInstance.get(
				`${process.env.REACT_APP_API_URL}/prices`
			);
			setPrices(response.data);

			const salePricesResponse = await axiosInstance.get(
				`${process.env.REACT_APP_API_URL}/sale-prices`
			);
			setSalePrices(salePricesResponse.data);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const addPrice = async (newPrice) => {
		try {
			const response = await axiosInstance.post(
				`${process.env.REACT_APP_API_URL}/prices`,
				newPrice
			);
			setPrices([...prices, response.data]);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const addSalePrice = async (newSalePrice) => {
		try {
			const response = await axiosInstance.post(
				`${process.env.REACT_APP_API_URL}/sale-prices`,
				newSalePrice
			);
			setSalePrices([...salePrices, response.data]);
			setShowSalePricesModal(false);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const updatePrice = async (priceId, updatedPrice) => {
		try {
			await axiosInstance.put(
				`${process.env.REACT_APP_API_URL}/prices/${priceId}`,
				updatedPrice
			);
			setPrices(
				prices.map((price) =>
					price.id === priceId ? { ...price, ...updatedPrice } : price
				)
			);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const deletePrice = async (priceId) => {
		try {
			await axiosInstance.delete(
				`${process.env.REACT_APP_API_URL}/prices/${priceId}`
			);
			setPrices(prices.filter((price) => price.id !== priceId));
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const updateSalePrice = async (salePriceId, field, value) => {
		try {
			const updatedSalePrice = {
				[field]: value,
			};
			await axiosInstance.put(
				`${process.env.REACT_APP_API_URL}/sale-prices/${salePriceId}`,
				updatedSalePrice
			);
			setSalePrices(
				salePrices.map((salePrice) =>
					salePrice.id === salePriceId
						? { ...salePrice, ...updatedSalePrice }
						: salePrice
				)
			);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const deleteSalePrice = async (salePriceId) => {
		try {
			await axiosInstance.delete(
				`${process.env.REACT_APP_API_URL}/sale-prices/${salePriceId}`
			);
			setSalePrices(
				salePrices.filter((salePrice) => salePrice.id !== salePriceId)
			);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleAddButtonClick = () => {
		setShowAddForm(true);
	};

	const handleSalePricesButtonClick = () => {
		setShowSalePricesModal(true);
	};

	const handleModalClose = () => {
		setShowAddForm(false);
		setShowSalePricesModal(false);
	};

	return (
		<div className={styles.pricesContainer}>
			<h2>Prices</h2>
			<table className={styles.pricesTable}>
				<thead>
					<tr>
						<th>AI</th>
						<th>Price</th>
						<th>Set Price</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{sortedPrices.map((price) => (
						<React.Fragment key={price.id}>
							<tr>
								<td>{price.ai}</td>
								<td>
									{price.parsedPrice !== undefined
										? (Number(price.parsedPrice.replace('$', '')) / 1000)
												.toFixed(6)
												.replace(/\.?0+$/, '')
										: ''}
								</td>
								<td>
									<input
										type='number'
										name='setPrice'
										value={price.setPrice || ''}
										onChange={(event) =>
											updatePrice(price.id, {
												...price,
												setPrice: event.target.value || 0,
											})
										}
									/>
								</td>
								<td>
									<button onClick={() => deletePrice(price.id)}>Delete</button>
								</td>
							</tr>
						</React.Fragment>
					))}
				</tbody>
			</table>
			<button onClick={handleAddButtonClick}>Add New Price</button>
			{showAddForm && (
				<AddPriceForm
					addPrice={addPrice}
					onClose={handleModalClose}
					fetchData={fetchData}
				/>
			)}
			<table className={styles.pricesTable}>
				<thead>
					<tr>
						<th>Sale Price</th>
						<th>Discount</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{sortedSalePrices.map((salePrice) => (
						<tr key={salePrice.id}>
							<td>
								{' '}
								<input
									type='number'
									value={salePrice.salePrice || ''}
									onChange={(event) =>
										updateSalePrice(
											salePrice.id,
											'salePrice',
											event.target.value || 0
										)
									}
								/>
							</td>
							<td>
								{' '}
								<input
									type='number'
									value={salePrice.discount || ''}
									onChange={(event) =>
										updateSalePrice(
											salePrice.id,
											'discount',
											event.target.value || 0
										)
									}
								/>
							</td>
							<td>
								<button onClick={() => deleteSalePrice(salePrice.id)}>
									Delete
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<button onClick={handleSalePricesButtonClick}>Add New Sale Price</button>
			{showSalePricesModal && (
				<SalePricesModal
					onClose={handleModalClose}
					addSalePrice={addSalePrice}
				/>
			)}
		</div>
	);
};

export default Prices;
