import React, { useState } from 'react';
import styles from './SalePricesModal.module.css';

const SalePricesModal = ({ onClose, addSalePrice }) => {
	const [salePriceData, setSalePriceData] = useState({
		salePrice: '',
		discount: '',
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setSalePriceData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		addSalePrice(salePriceData);
		onClose();
	};

	return (
		<div className={styles.modal}>
			<div className={styles.modalContent}>
				<span className={styles.close} onClick={onClose}>
					&times;
				</span>
				<h2>Add New Sale Price</h2>
				<form onSubmit={handleSubmit} className={styles.addSalePriceForm}>
					<div>
						<label>Sale Price:</label>
						<input
							type='number'
							name='salePrice'
							value={salePriceData.salePrice}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div>
						<label>Discount:</label>
						<input
							type='number'
							name='discount'
							value={salePriceData.discount}
							onChange={handleInputChange}
							required
						/>
					</div>
					<button type='submit'>Add Sale Price</button>
				</form>
			</div>
		</div>
	);
};

export default SalePricesModal;
