import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from './axiosInterceptor';
import { decodeToken } from 'react-jwt';
import Admin from './components/Admin/Admin';
import Auth from './components/Auth/Auth';
import CompanyChat from './components/CompanyChat/CompanyChat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css';
import './App.css';

const App = () => {
	// Состояния компонента
	const [companies, setCompanies] = useState(
		JSON.parse(localStorage.getItem('companies')) || []
	); // Массив компаний
	const [selectedCompany, setSelectedCompany] = useState(
		JSON.parse(localStorage.getItem('selectedCompany')) || null
	); // Выбранная компания
	const [token, setToken] = useState(null); // Токен аутентификации
	const [userId, setUserId] = useState(null);
	const [userEmail, setUserEmail] = useState('');
	const [role, setRole] = useState('');

	const isUUID = (value) => {
		const regex =
			/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
		return regex.test(value);
	};

	const getTokenLinkFromUrl = () => {
		const currentUrl = window.location.href;
		const parts = currentUrl.split('/');
		const tokenLink = parts[parts.length - 1];
		return tokenLink;
	};
	const tokenLink = getTokenLinkFromUrl();

	useEffect(() => {
		const assignToken = async () => {
			if (isUUID(tokenLink)) {
				try {
					console.log(userId);
					console.log(tokenLink);
					// Выполняем запрос к серверу, чтобы начислить токены пользователю
					const response = await axiosInstance.post(
						`${process.env.REACT_APP_API_URL}/token-link/${userId}/assign-token`,
						{ tokenLink: tokenLink }
					);
					if (response.data === true) {
						toast.success('Токены успешно добавлены!');
					} else {
						toast.error('Не удалось добавить токены.');
					}
				} catch (error) {
					toast.error('Произошла ошибка при добавлении токенов.');
				}
			}
		};

		if (token) {
			assignToken();
		}
	}, [token, tokenLink, userId]);

	// Обработчик входа в систему (аутентификации)
	const handleLogin = (token) => {
		setToken(token); // Установка токена аутентификации в состояние
		const decodedToken = decodeToken(token);
		const userId = decodedToken.id;
		const role = decodedToken.roles[0].value;
		setRole(role);
		setUserId(userId);
		setUserEmail(decodedToken.email);
	};

	useEffect(() => {
		const fetchCompanies = async () => {
			if (userId === null) {
				return;
			}
			const storedCompanies = JSON.parse(localStorage.getItem('companies'));
			if (!storedCompanies || storedCompanies.length === 0) {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/users/${userId}/companies`
					);

					console.log(response.data);

					if (response.status === 200) {
						const companiesFromServer = response.data;
						setCompanies(companiesFromServer);
						localStorage.setItem(
							'companies',
							JSON.stringify(companiesFromServer)
						);
					} else {
						console.error(
							'Failed to fetch companies:',
							response.status,
							response.statusText
						);
						// Handle error case here
					}
				} catch (error) {
					console.error('Error occurred while fetching companies:', error);
					console.error('Response data:', error.response.data);
					console.error('Response status:', error.response.status);
					console.error('Response headers:', error.response.headers);
				}
			}
		};

		fetchCompanies();
	}, [userId]);

	//useEffect для сохранения компаний в локальном хранилище
	useEffect(() => {
		localStorage.setItem('companies', JSON.stringify(companies)); // Сохранение компаний в локальном хранилище
	}, [companies]);

	useEffect(() => {
		localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
	}, [selectedCompany]);

	// Обработчик клика по компании
	const handleCompanyClick = (company) => {
		setSelectedCompany(company); // Установка выбранной компании в состояние
	};

	// Обработчик добавления новой компании
	const handleAddCompany = async (newCompany) => {
		const { name, description } = newCompany;
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/companies`,
				{
					name,
					description,
					userId,
					members: newCompany.members,
				}
			);
			console.log(response);
			if (response.status === 201) {
				const createdCompany = response.data;
				const updatedCompany = {
					...newCompany,
					id: createdCompany.id,
					userId: userId,
				};
				setCompanies([...companies, updatedCompany]);
			} else {
				console.error(
					'Failed to create company:',
					response.status,
					response.statusText
				);
				// Handle error case here
			}
		} catch (error) {
			console.error('Error occurred while creating company:', error);
			// Handle error case here
		}
	};

	// Обработчик удаления компании
	const handleDeleteCompany = async (company) => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_API_URL}/users/${userId}/companies/${company.id}`
			);

			setCompanies(
				companies.filter((c) => c.id !== company.id || c.userId !== userId)
			);

			// Сброс выбранной компании, если удаленная компания была выбранной
			if (selectedCompany && selectedCompany.id === company.id) {
				setSelectedCompany(null);
			}
		} catch (error) {
			console.error('Error occurred while deleting company:', error);
			// Обработка ошибки здесь
		}
	};

	// Обработчик выхода из системы (аутентификации)
	const handleLogout = () => {
		setToken(null); // Сброс токена аутентификации
		setUserId(null);
		localStorage.clear();
	};

	//useEffect для сохранения токена аутентификации в локальном хранилище
	useEffect(() => {
		if (token) {
			localStorage.setItem('token', token); // Сохранение токена в локальном хранилище
		} else {
			localStorage.removeItem('token'); // Удаление токена из локального хранилища
		}
	}, [token]);

	// Если нет токена аутентификации, отображается компонент Auth (аутентификации)
	if (!token) {
		return <Auth onLogin={handleLogin} />;
	}

	if (role === 'ADMIN') {
		return <Admin token={token} onLogout={handleLogout} />;
	}

	// Отображение компонента CompanyChat (чата компаний)
	return (
		<div className='app'>
			<ToastContainer />
			<CompanyChat
				companies={companies}
				selectedCompany={selectedCompany}
				onCompanyClick={handleCompanyClick}
				onAddCompany={handleAddCompany}
				onDeleteCompany={handleDeleteCompany}
				onLogout={handleLogout}
				userId={userId}
				userEmail={userEmail}
			/>
		</div>
	);
};

export default App;
