import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Input } from 'reactstrap';

const CompanySettingsModal = ({ company, isOpen, onClose }) => {
	const [userEmails, setUserEmails] = useState([]);
	const [newUserEmail, setNewUserEmail] = useState('');
	const [error, setError] = useState(null);

	const fetchUserEmails = useCallback(async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/companies/${company.id}/users-emails`
			);
			if (response.status === 200) {
				setUserEmails(response.data);
				setError(null);
			}
		} catch (error) {
			setError(error.response?.data?.message || 'An error occurred');
			console.error('Error fetching user emails:', error);
		}
	}, [company.id]);

	useEffect(() => {
		if (isOpen) {
			fetchUserEmails();
		}
	}, [isOpen, fetchUserEmails]);

	const addUserToCompany = async (userEmail) => {
		try {
			await axios.post(
				`${process.env.REACT_APP_API_URL}/companies/${company.id}/add-user`,
				{ email: userEmail }
			);
			// Refresh user emails after adding a user
			fetchUserEmails();
			setError(null);
		} catch (error) {
			setError(error.response?.data?.message || 'An error occurred');
			console.error('Error adding user to company:', error);
		}
	};

	const removeUserFromCompany = async (userEmail) => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_API_URL}/companies/${
					company.id
				}/remove-user/${encodeURIComponent(userEmail)}`
			);
			// Refresh user emails after removing a user
			fetchUserEmails();
			setError(null);
		} catch (error) {
			console.log(error.response.data.message);
			setError(error.response?.data?.message || 'An error occurred');
			console.error('Error removing user from company:', error);
		}
	};

	return (
		<div className={`company-settings-modal ${isOpen ? 'open' : ''}`}>
			<div className='modal-content'>
				<h4>Company Settings</h4>
				<p>{company.name}</p>
				<div className='user-list'>
					{userEmails.length > 0 ? (
						userEmails.slice(1).map((userEmail) => (
							<div key={userEmail} className='user-item'>
								{userEmail}
								<Button
									variant='danger'
									style={{ marginBottom: '5px' }}
									size='sm'
									onClick={() => removeUserFromCompany(userEmail)}>
									Remove
								</Button>
							</div>
						))
					) : (
						<p>No users with access to this company</p>
					)}
				</div>
				<div className='add-user'>
					<FloatingLabel
						controlId='floatingInput'
						label='Email address'
						className='mb-3'>
						<Input
							type='email'
							placeholder='name@example.com'
							value={newUserEmail}
							onChange={(e) => setNewUserEmail(e.target.value)}
							required
						/>
					</FloatingLabel>
				</div>
				{error && <p className='text-danger'>{error}</p>}
				<Button size='sm' onClick={() => addUserToCompany(newUserEmail)}>
					Add User
				</Button>
				<div className='modal-buttons'>
					<Button size='sm' onClick={onClose}>
						Close
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CompanySettingsModal;
